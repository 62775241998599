<template>
  <div>
    <h1>{{translate('contact')}}</h1>
    <p>
      {{translate('featureRequests')}}
    </p>
    <p style="font-weight: bold">
      contact@armadama.com
    </p>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  data () {
    return {
    }
  }
}
</script>

<style>

</style>
