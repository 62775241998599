<script>
  export default {
    name: 'ChartsMixin',
    data() {
      return {
        chart: null
      }
    },
    computed: {
    },
    methods: {
      createChart(element, type, chartData, options) {
        var ctx = document.getElementById(element).getContext('2d');
        this.chart = new Chart(ctx, {
            type: type,
            data: chartData,
            options: options
        });
      },
      addData(chartData) {
        this.chart.data = chartData;
        this.chart.update();
      }
    }
  }
</script>
