<template>
  <div class="attendanceChart">
    <canvas id="dayOfWeekAttendance" width="200" height="200"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js'
import ChartsMixin from '@/mixins/Charts'
import moment from 'moment-timezone'

  export default {
    name: 'DayOfWeekAttendanceChart',
    mixins: [
      ChartsMixin
    ],
    components: { },
    props: [
      'dataKey'
    ],
    data() {
      return {
        chart: null,
        options: {
          responsive: true,
          title: {
            display: true,
            text: this.translate('dailyAttendance'),
            fontColor: '#2e2d2d'
          },
          legend:  {
            display: false
          },
          elements: {
            rectangle: {
              borderWidth: 1,
              backgroundColor: 'rgb(10,42,84)'
            }
          }
        }
      }
    },
    computed: {
      totalAttendanceMetrics: function() {
        return this.$store.getters.totalAttendanceMetrics(this.dataKey);
      },
      formattedData: function() {
        let attendanceByDay = this.totalAttendanceMetrics && this.totalAttendanceMetrics.byWeek;
        if(attendanceByDay) {
          let labels = Object.keys(this.totalAttendanceMetrics.byWeek).map(key => key.substring(5, key.length))
          let values = Object.values(this.totalAttendanceMetrics.byWeek)

          let dayCount = {};
          let labelMap = {};
          Object.keys(attendanceByDay).forEach(day => {
              let dayLabel = moment(day).format('ddd');
              let dayNumber = moment(day).day();
              labelMap[dayNumber] = dayLabel;
              dayCount[dayLabel] = dayCount[dayLabel] ? dayCount[dayLabel] + attendanceByDay[day] : attendanceByDay[day];
          })
          const orderedDays = {};
          const orderedLabels = {};
          Object.keys(labelMap).sort().forEach(key =>  {
            orderedLabels[key] = labelMap[key];
          });
          Object.values(orderedLabels).forEach(label => {
              orderedDays[label] = dayCount[label];
          })

          let chartData = {
            labels: Object.keys(orderedDays),
            datasets: [
              {
                data: Object.values(orderedDays)
              }
            ]
          };

          return chartData;
        }
      }
    },
    watch: {
      formattedData: function (val) {
        if(val) {
          if(!this.chart) {
            this.createChart('dayOfWeekAttendance', 'bar', val, this.options)
          } else {
            this.addData(val)
          }

        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .attendanceChart {
    height: 300px;
    width: 300px;
    margin: auto;

    @include sm {
      height: 300px;
      width: 300px;
    }

    @include md {
      height: 350px;
      width: 350px;
    }

    @include lg {
      height: 500px;
      width: 500px;
    }
  }

</style>
