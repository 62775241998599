<template>
  <div class="academy-wrapper">
    <h1>{{translate('metrics')}}</h1>
    <div class="academy-element" v-if="academy && Object.keys(academy).length">
      <div class="select-wrapper">
        <select v-model="academy" v-if="userAcademies && userAcademies.length" class="dashboard-select">
          <option :value="ua" v-for="(ua, index) in userAcademies" :key="index">
            {{ua.name}}
          </option>
        </select>
      </div>
      <div class="datepicker-container" v-if="isLoggedIn">
        <Datepicker :value-attr="startDate" @value-changed="onStartDateChange" ></Datepicker>
        <Datepicker :value-attr="endDate" @value-changed="onEndDateChange" ></Datepicker>
      </div>
    </div>
    <div class="secondary-element">
      <div class="chart-element">
        <DailyAttendanceChart :dataKey="academy._id" />
      </div>
      <div class="chart-element">
        <DayOfWeekAttendanceChart :dataKey="academy._id" />
      </div>

      <div class="member-select-wrapper" v-if="members && members.length">
        <div class="select-wrapper">
          <select v-model="member" class="member-select">
            <option :value="am" v-for="(am, index) in members" :key="index">
              {{am.member.alias}}
            </option>
          </select>
        </div>
      </div>
      <div class="chart-element single-element">
        <WeeklyUserAttendanceChart :dataKey="academy._id+member._id" v-if="members && members.length"/>
      </div>
    </div>
  </div>
</template>

<script>
  import DailyAttendanceChart from '@/components/charts/DailyAttendanceChart'
  import WeeklyUserAttendanceChart from '@/components/charts/WeeklyUserAttendanceChart'
  import DayOfWeekAttendanceChart from '@/components/charts/DayOfWeekAttendanceChart'
  import moment from 'moment-timezone'
  import Datepicker from 'vue3-datepicker-lite'
  import orderBy from 'lodash.orderby'

  export default {
    name: 'Metrics',
    components: {
      Datepicker,
      DailyAttendanceChart,
      DayOfWeekAttendanceChart,
      WeeklyUserAttendanceChart
    },
    data() {
      return {
        defaultImg: 'https://user-images.armadama.com/default/profile.jpg',
        startDate: moment().subtract(30, 'days').startOf('day').format('YYYY/MM/DD'),
        endDate: moment().format('YYYY/MM/DD'),
        selectedAcademy: null,
        selectedMember: null
      }
    },
    computed: {
      currentUser: function() {
        return this.$store.getters.getUser;
      },
      userAcademies: function() {
        let userAcademies = (this.currentUser && this.$store.getters.getUserAcademies(this.currentUser._id)) || {};
        return userAcademies ? userAcademies.student : []
      },
      academy: {
        get() {
          return this.selectedAcademy || (this.userAcademies ? this.userAcademies[0] : {})
        },
        set(value) {
          this.selectedAcademy = value;
        }
      },
      members: function() {
        let academyMembers = this.$store.getters.getAcademyMembers(this.academy._id);
        return academyMembers ? orderBy(academyMembers, 'member.alias') : []
      },
      member: {
        get() {
          return this.selectedMember || (this.members ? this.members[0] : {})
        },
        set(value) {
          this.selectedMember = value;
        }
      },
      isLoggedIn: function() {
        return this.$store.getters.isLoggedIn;
      }
    },
    watch: {
      academy: function (val) {
        if(val && val._id) {
          this.getRelatedData(val);
        }
      },
      member: function (val) {
        if(val && val._id) {
          this.getMemberRelatedData(val);
        }
      }
    },
    methods: {
      onStartDateChange(value) {
        this.startDate = value ? moment(value).toISOString() : this.startDate;
        this.getRelatedData()
      },
      onEndDateChange(value) {
        this.endDate = value ? moment(value).endOf('day').toISOString() : this.endDate;
        this.getRelatedData()
      },
      async getData() {
        this.$store.commit('isLoading', true);

        try {
          if(this.currentUser) {
            await Promise.all([
              this.$store.dispatch('getUserAcademies', { id: this.currentUser._id }),
            ]);
          }
        } catch(e) {
          console.error('Unable to get user academies', e)
        }

        this.$store.commit('isLoading', false);
      },
      async getRelatedData(academy) {
        this.$store.commit('isLoading', true);

        try {
          let params = {
            startDate: this.startDate,
            endDate: this.endDate,
            timezone: moment.tz.guess(),
            academyId: this.academy._id
          }

          await Promise.all([
            this.$store.dispatch('getTotalAttendanceMetrics', { key: this.academy._id, params }),
            this.$store.dispatch('listAcademyMembers', { key: this.academy._id, params: { academyId: this.academy._id } })
          ]);

        } catch(e) {
          console.error('Unable to get academy members', e)
        }

        this.getMemberRelatedData()

        this.$store.commit('isLoading', false);
      },
      async getMemberRelatedData() {
        this.$store.commit('isLoading', true);

        try {
          let params = {
            startDate: this.startDate,
            endDate: this.endDate,
            timezone: moment.tz.guess(),
            academyId: this.academy._id,
            memberId: this.member._id
          }

          await Promise.all([
            this.$store.dispatch('getUserAttendanceMetrics', { key: this.academy._id+this.member._id, params }),
          ]);
        } catch(e) {
          console.error('Unable to get academy members', e)
        }

        this.$store.commit('isLoading', false);
      }
    },
    async mounted() {
      await this.getData();
    }
  }
</script>

<style lang="scss" scoped>
.no-data {
  text-align: center;
}

.academy-wrapper {
  color: #2e2d2d;
}

.academy-element {
}

.content-wrapper {
}

.secondary-element {
  @include sm {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 5px;
    grid-column-gap: 5px;
  }
}

.dashboard-select {
  font-size: 25px;
  background-color: #efefef;
  color: #2e2d2d;
  border: none;
  cursor: pointer;
}

.member-select {
  font-size: 25px;
  background-color: #efefef;
  color: #2e2d2d;
  border: none;
  cursor: pointer;
}

.member-select-wrapper {
  margin-top: 30px;
  grid-column: 1 / span 2;
}

.single-element {
  grid-column: 1 / span 2;
}

.select-wrapper {
  text-align: center;
}

.datepicker-container {
  padding-top: 20px;
  div {
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;

    .picker__frame {
      width: 300px;
    }
  }
}

.datepicker-container input {
  background-color: white;
  border-radius: 5px;
  height: 30px;
  width: 120px;

  @include md {
    width: 200px;
  }
}

.picker__frame {
  width: 300px;
}

.picker__footer button {
  background-color: rgb(10,42,84) !important;
  height: 30px;
  width: 75px;
}

h2 {
  padding-left: 0;

  text-align: center;
  @include md {
    text-align: left;
  }
}

a {
  color: rgb(10,42,84);
}
</style>
