<template>
  <div class="attendanceChart">
    <canvas id="dailyAttendance" width="200" height="200"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js'
import ChartsMixin from '@/mixins/Charts'

  export default {
    name: 'DailyAttendanceChart',
    mixins: [
      ChartsMixin
    ],
    components: { },
    props: [
      'dataKey'
    ],
    data() {
      return {
        chart: null,
        options: {
          responsive: true,
          title: {
            display: true,
            text: this.translate('dailyAttendance'),
            fontColor: '#2e2d2d'
          },
          legend:  {
            display: false
          },
          elements: {
            line: {
              borderColor: 'rgb(10,42,84)',
              borderWidth: 1,
              backgroundColor: 'rgba(10,42,84, 0.1)',
              tension: 0
            },
            point: {
              backgroundColor: 'rgb(10,42,84)'
            }
          }
        }
      }
    },
    computed: {
      totalAttendanceMetrics: function() {
        return this.$store.getters.totalAttendanceMetrics(this.dataKey);
      },
      formattedData: function() {
        if(this.totalAttendanceMetrics && this.totalAttendanceMetrics.byWeek) {
          let labels = Object.keys(this.totalAttendanceMetrics.byWeek).map(key => key.substring(5, key.length))
          let values = Object.values(this.totalAttendanceMetrics.byWeek)
          let chartData = {
            labels: labels,
            datasets: [
              {
                data: values
              }
            ]
          }

          return chartData;
        }
      }
    },
    watch: {
      formattedData: function (val) {
        if(val) {
          if(!this.chart) {
            this.createChart('dailyAttendance', 'line', val, this.options)
          } else {
            this.addData(val)
          }

        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .attendanceChart {
    height: 300px;
    width: 300px;
    margin: auto;

    @include sm {
      height: 300px;
      width: 300px;
    }

    @include md {
      height: 350px;
      width: 350px;
    }

    @include lg {
      height: 500px;
      width: 500px;
    }
  }

</style>
