import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home'
import Login from '../views/Login'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import ToC from '../views/ToC.vue'
import Contact from '../views/Contact.vue'
import Account from '../views/Account.vue'
import Academy from '../views/Academy.vue'
import Class from '../views/Class.vue'
import Dashboard from '../views/Dashboard.vue'
import Metrics from '../views/Metrics.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Armada MA - home',
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      title: 'Armada MA - dashboard',
    }
  },
  {
    path: '/metrics',
    name: 'Metrics',
    component: Metrics,
    meta: {
      title: 'Armada MA - metrics',
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Armada MA - login',
    },
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: {
      title: 'Armada MA - privacy',
    }
  },
  {
    path: '/terms-and-conditions',
    name: 'ToC',
    component: ToC,
    meta: {
      title: 'Armada MA - terms and conditions'
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      title: 'Armada MA - contact'
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      title: 'Armada MA - Account'
    }
  },
  {
    path: '/academies/:id',
    name: 'Academy',
    component: Academy,
    meta: {
      title: 'Armada MA - Academy'
    }
  },
  {
    path: '/classes/:id',
    name: 'Class',
    component: Class,
    props: route => ({ startDate: route.query.startDate, endDate: route.query.endDate }),
    meta: {
      title: 'Armada MA - Class'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // if(to.path !== '/login' && !localStorage.authtoken) {
  //   next( '/login' )
  // }

  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  next();
})

export default router
